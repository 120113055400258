export default function logs(dataTime) {
    return `<p class="MsoNormal" align="center"><span style="font-size: 18pt;"><strong>工作日志</strong></span></p>
    <p class="MsoNormal" align="center"><span style="font-size: 14pt;"><strong>日期：${dataTime}</strong></span></p>
    <table class="MsoTableElegant" border="1" cellspacing="0" cellpadding="0">
    <tr>
     <td  valign="top" width="385">
     <p class="MsoNormal" align="center">内容</p>
     </td>
     <td valign="top" width="140">
     <p class="MsoNormal" align="center">时间</p>
     </td>
     <td valign="top" width="131">
     <p class="MsoNormal" align="center">进度</p>
     </td>
     <td valign="top" width="135">
     <p class="MsoNormal" align="center">协作人</p>
     </td>
     </tr>
     <tr>
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;1:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;2:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
    
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;3:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
     
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;4</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
     
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;5:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
    
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;6:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span style="font-family: Tahoma;"><span lang="EN-US">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
    
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;7:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
     
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;8:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span style="font-family: Tahoma;"><span lang="EN-US">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
     
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;9</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     <tr>
    
     <td valign="top" width="352">
     <p class="MsoNormal"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;10:</span></span></p>
     </td>
     <td width="140">
     <p class="MsoNormal" align="center"><span style="font-family: Tahoma;"><span lang="EN-US">0小时</span></span></p>
     </td>
     <td width="131">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;0%</span></span></p>
     </td>
     <td width="135">
     <p class="MsoNormal" align="center"><span lang="EN-US"><span style="font-family: Tahoma;">&nbsp;</span></span></p>
     </td>
     </tr>
     </tbody>
     </table>
     <p class="MsoNormal">&nbsp;</p>
     <p class="MsoNormal">备注：<span lang="EN-US"><span style="font-family: Tahoma;">1</span></span>、请于每日下班前填写，如遇紧急或外出事务于第二天补回；</p>
     <p><span lang="EN-US">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2</span>、该日志用于考核员工绩效，未及时提交，按每次1分扣，每月超出<span lang="EN-US">三</span>次扣5<span lang="EN-US">分。</span></p>`
    }