<template>
	<a-modal v-model="show" title="日志信息" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>

				<a-col span="8">
					<a-form-model-item label="选择日期" label-position="top" prop="date">
						<a-date-picker :allowClear="false" @change="changeDate" valueFormat="YYYY-MM-DD" v-model="formData.date" format="YYYY-MM-DD"  type="date" placeholder="请选择日期" />
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col>
					<editor v-model="formData.content" @input="e => (formData.content = e)"></editor>
				</a-col>
			</a-row>
			<a-row style="margin-top:20px" :gutter="15">
				<a-col span="10">
					<attach-upload :value="formData.attachment" @input="e => (formData.attachment = e)"></attach-upload>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import attachUpload from '@/components/common/attach-upload.vue';
import upInput from '@/components/common/upInput.vue';
import editor from '@/components/component/editor.vue';
import moment from 'moment';
import Logs from './log'
export default {
	components: { upInput, attachUpload, editor },
	mounted() {
		// console.log(apiUtil.getUser());
	},
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			formData: {
				id:'',
				organId: apiUtil.getOrganId(''),
				content: '',
				date: '',
				attachment: []
			},
			formRule: {
				content: [{ required: true, message: '请输入日志内容' }],
				date: [{ required: true, message: '请选择日期' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					for(let k in this.formData){
						this.formData[k]=form[k]
					}
				} else {
					this.formData.id = '';
					this.formData.organId = apiUtil.getOrganId('');
					this.formData.date = utils.formatDate(new Date(),'yyyy-MM-DD');
					this.formData.attachment = [];
					this.formData.content=Logs(utils.formatDate(new Date(),'yyyy年MM月DD日'))
				}
				
			});
		},
		changeDate(e){
			let date=e.split('-')
			console.log(date[0])
			this.formData.content=Logs(`${date[0]}年${date[1]}月${date[2]}日`)
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/work-log/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								// this.$refs.formRef.resetFields();
								utils.closeSpin();
								this.show = false;
							});
						}
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}</style>
